import revive_payload_client_4sVQNw7RlN from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import pwa_client_Eorgdr12jA from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import floating_vue_BiOD74u9sH from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/plugins/floating-vue.ts";
import leaflet_client_7dEOJA4hbk from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/plugins/leaflet.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  pwa_client_Eorgdr12jA,
  chunk_reload_client_UciE0i6zes,
  floating_vue_BiOD74u9sH,
  leaflet_client_7dEOJA4hbk
]